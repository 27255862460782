import React, { useState, forwardRef, useImperativeHandle } from 'react'
import SwipeableViews from 'react-swipeable-views'
import Pagination from '../../atoms/DotPagination/Pagination'
import {
  FileAttachmentsPreviewContainer,
  ItemContainer,
  VideConatiner,
  ImageContainer,
  AudioContainer
} from './FileAttachmentsPreview.style'

const FileAttachmentsPreview = forwardRef(({ data }, ref) => {
  const [index, setIndex] = useState(0)

  const handleChangeIndex = (i) => {
    setIndex(i)
  }

  useImperativeHandle(ref, () => ({
    ref,
    handleChangeIndex
  }))

  return (
    <FileAttachmentsPreviewContainer>
      <SwipeableViews
        index={index}
        onChangeIndex={handleChangeIndex}
        enableMouseEvents
        ref={ref}
      >
        {data.map((item) => {
          return (
            <ItemContainer key={item.name}>
              {item.type === 'video' && (
                <VideConatiner>
                  <video controls width="100%" height="400px">
                    <source src={item.presignedUrl} />
                    Sorry, your browser does not support embedded videos.
                  </video>
                </VideConatiner>
              )}
              {item.type === 'photo' && (
                <ImageContainer>
                  <img
                    src={item.presignedUrl}
                    alt={item.name}
                    style={{ width: 'auto', height: 380 }}
                  />
                </ImageContainer>
              )}
              {item.type === 'audio' && (
                <AudioContainer>
                  <audio controls src={item.presignedUrl} id="audio-element">
                    Your browser does not support the
                    <code>audio</code> element.
                  </audio>
                </AudioContainer>
              )}
            </ItemContainer>
          )
        })}
      </SwipeableViews>
      <Pagination
        dots={data.length}
        index={index}
        onChangeIndex={handleChangeIndex}
      />
    </FileAttachmentsPreviewContainer>
  )
})

export default FileAttachmentsPreview
